<template>
  <div>
    <footer class="footer style7">
      <div class="container">
        <div class="container-wapper">
          <div v-if="contactInfo" class="row">
            <div class="box-footer col-xs-12 col-sm-6 col-md-6 col-lg-4">
              <div class="widget-box">
                <ul class="menu">
                  <!--<h2 class="widgettitle">{{contactInfo.bussinessname}}</h2>-->
                  <h2 class="widgettitle">Informacion</h2>
                  <li class="menu-item">
                    <a href="#" class="footer-a"
                      ><span class="fas fa-map-marker-alt"></span>
                      {{contactInfo.address1}} - {{contactInfo.address2}}</a
                    >
                  </li>
                  <li class="menu-item">
                    <a href="#" class="footer-a"
                      ><span class="fa fa-phone"></span>
                      {{contactInfo.phone1}} / {{contactInfo.phone2}}</a
                    >
                  </li>
                  <li class="menu-item">
                    <a href="#" class="footer-a"
                      ><span class="fa fa-envelope-o"></span
                      >{{contactInfo.email}}</a
                    >
                  </li>
                  <li class="menu-item">
                    <a href="#" class="footer-a"
                      ><span class="fa fa-clock-o"></span>{{contactInfo.workhours}}</a
                    >
                  </li>
                  <li class="menu-item">
                      <div class="tanajil-socials">
                            <ul class="socials">
                                <li>
                                    <a v-if="(networks !== undefined) ? (networks.facebook!=null) : false" :href="formatUrl((networks !== undefined) ? networks.facebook : '')" class="social-item facebook-icon" target="_blank">
                                    <i class="icon fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a v-if="(networks !== undefined) ? (networks.twitter!=null) : false" :href="formatUrl((networks !== undefined) ? networks.twitter : '')" class="social-item twitter-icon" target="_blank">
                                    <i class="icon fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a v-if="(networks !== undefined) ? (networks.instagram!=null) : false" :href="formatUrl((networks !== undefined) ? networks.instagram : '')" class="social-item instagram-icon" target="_blank">
                                    <i class="icon fa fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a v-if="(networks !== undefined) ? (networks.whatsapp!=null) : false" :href="(networks !== undefined) ? ('https://wa.me/54' + networks.whatsapp) : ''" class="social-item whatsapp-icon" target="_blank">
                                    <i class="icon fa fa-whatsapp"></i>
                                    </a>
                                </li>
                                <li>
                                    <a v-if="(networks !== undefined) ? (networks.telegram!=null) : false" :href="(networks !== undefined) ? networks.telegram : ''" class="social-item telegram-icon" target="_blank">
                                    <i class="icon fa fa-telegram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="box-footer col-xs-12 col-sm-6 col-md-6 col-lg-2" v-if="!catalogView">
              <div class="tanajil-custommenu default">
                <h2 class="widgettitle">Atajos</h2>
                <ul class="menu">
                  <li class="menu-item">
                    <router-link :to="{ name: 'Home' }" class="footer-a"
                      >Inicio</router-link
                    >
                  </li>
                  <li class="menu-item">
                    <router-link :to="{ name: 'Store' }" class="footer-a"
                      >Tienda</router-link
                    >
                  </li>
                  <li class="menu-item">
                    <router-link :to="{ name: 'Contact' }" class="footer-a"
                      >Contactanos</router-link
                    >
                  </li>
                  <li class="menu-item">
                    <router-link :to="{ name: 'About' }" class="footer-a"
                      >Nosotros</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 w-100 text-center" style="margin-bottom: 10px;">
              <span class="text-light">Powered by <a href="#" class="text-primary">QUPROSHOP</a></span>
            </div>
          </div>
          <!-- <div class="footer-end">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="coppyright">
                  <a href="templateshub.net">Templateshub</a>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="tanajil-payment">
                  <img src="/assets/images/payments.png" alt="img" />
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </footer>

    <!-- Mobile Footer -->
    <div class="footer-device-mobile">
      <MiniCart :onMobile="true" :showCart=showCart @close-cart="showCart = false" class="modal-fixer" />
      <MiniLogin :onMobile="true" :showLogin=showLogin @close-login="showLogin = false" class="modal-fixer" />
      <div class="wapper">
        <div class="footer-device-mobile-item device-home" v-if="!catalogView">
          <router-link :to="{ name: 'Home' }">
            <span class="icon">
              <i class="fal fa-home-lg-alt"></i>
            </span>
            Principal
          </router-link>
        </div>
        <!--
        <div class="footer-device-mobile-item device-home device-wishlist">
          <a href="#">
            <span class="icon">
              <i class="fa fa-whatsapp"></i>
              <img src="" alt="" />
            </span>
            Whatsapp
          </a>
        </div>
        -->
        <div class="footer-device-mobile-item device-home device-wishlist" v-if="!catalogView">
          <router-link :to="{ name: 'Store' }">
            <a href="#">
              <span class="icon">
                <i class="fal fa-store"></i>
                <img src="" alt="" />
              </span>
              Tienda
            </a>
          </router-link>
        </div>
        <div class="footer-device-mobile-item device-home device-cart">
          <a @click="openNav">
            <span class="icon">
              <i class="fal fa-shopping-cart"></i>
              <span  v-if="basketCount > 0" class="count-icon">
                {{ basketCount }}
              </span>
            </span>
            <span class="text">Carrito</span>
          </a>
        </div>
        <div class="footer-device-mobile-item device-home device-user">
          <a @click="openLogin">
            <span class="icon">
              <i class="fal fa-user"></i>
            </span>
            Cuenta
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MiniCart from "./MiniCart";
import MiniLogin from "./MiniLogin";

export default {
  components: { MiniCart, MiniLogin },

  watch: {
    imgSrcs(newImageSrc){
      if( newImageSrc !== undefined) {
          if(newImageSrc.logo !== undefined){
              this.logo = newImageSrc.logo;
          }
      }
    },
    contact(newContact){
      if( newContact !== undefined) {
        this.contactInfo = newContact;
      }
    }
  },

  computed: {
    ...mapGetters({
      basketCount: "ecommerce/basketCount", 
      contact: "ecommerce/contact",
      networks: "ecommerce/networks"
    }),
    catalogView:{
      get(){
        return this.$route.name == 'Catalog'
      }
    }
  },

  data() {
    return {
      contactInfo: {
        address1:'',
        address2:'',
        phone1:'',
        phone2:'',
        email:'',
        workhours:''
      },
      showLogin: false,
      showCart: false,
      logo: "/img/logo-qupro.png",
    };
  },

  created (){
    if( this.imgSrcs !== undefined) {
        if(this.imgSrcs.logo !== undefined){
            this.logo = this.imgSrcs.logo;
        }
    }
    if( this.contact !== undefined) {
      this.contactInfo = this.contact;
    }
  },

  methods: {
    openNav() {
      // document.getElementsByClassName("mobile")[0].style.width = "100%";
      this.showCart = true
    },
    openLogin(){
      // document.getElementsByClassName("mobileLogin")[0].style.width = "100%";
      this.showLogin = true
    },
    closeLogin(){
        document.getElementsByClassName("mobileLogin")[0].style.width = "0";
    },
    formatUrl(url){
      if(url.startsWith('http')){
        return url;
      }else{
        return 'http://' + url;
      }
    }
  }
};
</script>

<style scoped>
.modal-fixer {
  position: fixed;
  z-index: 9999;
}
</style>
