<template>
  <a v-if="(networks !== undefined) ? (networks.whatsapp!=null) : false" class="whatsapp-widget show"
  :href="'https://wa.me/54' + networks.whatsapp" target="_blank">
    <i class="fab fa-whatsapp"></i>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
      ...mapGetters({
        networks: "ecommerce/networks"
      }),
    },
};
</script>

<style>
.whatsapp-widget {
  width: 50px;
  height: 50px;
  font-size: 24px;
  font-weight: 700;
  background: #40e15d;
  color: #fff;
  border-radius: 50%;
  position: fixed;
  bottom: 25px;
  right: 25px;
  text-align: center;
  line-height: 50px;
  z-index: 99;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.whatsapp-widget:hover {
  background: #3bd156;
  color: #fff;

}
@media screen and (max-width: 767px) {
  .whatsapp-widget {
    bottom: 70px;
    right: unset;
    left: 10px;
  }
}
</style>
