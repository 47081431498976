<template>
  <div class="login-container" v-losefocus="clickOutsideHandler">
    <div id="loginSidebar" class="sidebar" :class="{ mobileLogin: onMobile, abrite: showLogin }">
      <div class="login-title">
        <h3>Usuario</h3>
      </div>
      <a href="javascript:void(0)" class="closebtn" v-on:click="closeLogin">×</a>
      <transition name="fade">
        <div v-if="showError" class="alert alert-danger alert-container">
          <a class="close" @click.prevent="showError = false, errorMessage = '' ">&times;</a>
          <strong>{{errorMessage}}</strong>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="showSuccess" class="alert alert-success alert-container">
          <a class="close" @click.prevent="showSuccess = false, successMessage = '' ">&times;</a>
          <strong>{{successMessage}}</strong>
        </div>
      </transition>
      <div v-if="user!=null" class="login-main">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a :href="onMobile ? '#logoutMobile' : '#logout'" :aria-controls="onMobile ? 'logoutMobile' : 'logout'" role="tab" data-toggle="tab">
              {{user.name}}
            </a>
          </li>
          <li role="presentation">
            <a>{{user.email}}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane fade in active" :id="onMobile ? 'logoutMobile' : 'logout'">
            <div class="form-container">
              <form @submit.prevent="handleSignOut">
                <div class="form-group btn-login-footer">
                  <button type="submit" class="btn btn-default">
                    Cerrar sesión
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>

      <div v-else class="login-main">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a :href="onMobile ? '#loginMobile' : '#login'"
              :aria-controls="onMobile ? 'loginMobile' : 'login'"
              role="tab"
              data-toggle="tab">
                Iniciar Sesión
            </a>
          </li>
          <li role="presentation">
            <a :href="onMobile ? '#registerMobile' : '#register'"
              :aria-controls="onMobile ? 'registerMobile' : 'register'"
              role="tab"
              data-toggle="tab">
              Registrarse
            </a>
          </li>
        </ul>
        
        <!-- Tab panes -->
        <!-- Login Tab -->
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane fade in active" :id="onMobile ? 'loginMobile' : 'login'">
            <!-- Login Form -->
            <div class="form-container"  v-show="!showForgotPasswordForm && !showResetPasswordForm">
              <form @submit.prevent="handleSignIn">
                <div class="form-group">
                  <label>Email</label>
                  <input v-model="loginForm.email" type="email" class="form-control" required />
                </div>
                <div class="form-group">
                  <label>Contraseña</label>
                  <input v-model="loginForm.password" type="password" class="form-control" required /> 
                </div>
                <!-- <div class="form-group">
                  <div class="checkbox">
                    <input type="checkbox" id="rememberlogin" v-model="loginForm.remember"/>
                    <label for="rememberlogin" class="label-text"> Recordarme</label>
                  </div>
                </div> -->
                <div class="form-group btn-login-footer">
                  <button type="submit" class="btn btn-default">
                    Iniciar sesión
                  </button>
                </div>
                <div class="forgotpassword">
                  <span>
                    <a @click="showForgotPasswordForm = true;">
                      ¿Olvidaste tu contraseña?
                    </a>
                  </span>
                </div>
              </form>
            </div>
            <div class="form-container" v-show="showForgotPasswordForm">
              <form @submit.prevent="handlePasswordRecovery">
                <div class="form-group">
                  <label>Email</label>
                  <input v-model="recovery.email" type="email" class="form-control" required :readonly="loading" />
                </div>
                <div class="form-group btn-login-footer">
                  <button type="submit" :disabled="loading" class="btn btn-default">
                    Recuperar contraseña
                  </button>
                </div>
                <div class="form-group btn-login-footer">
                  <button @click.prevent="showForgotPasswordForm = false;" class="btn btn-default">
                    Volver a login
                  </button>
                </div>
              </form>
            </div>
            <div class="form-container" v-show="showResetPasswordForm">
              <form @submit.prevent="handleLoginAndCloseModal">
                <div class="form-group">
                  <label>Email</label>
                  <input v-model="recovery.email" type="email" class="form-control" readonly />
                </div>
                <div class="form-group">
                  <label>Token</label>
                  <input v-model="recovery.token" type="text" class="form-control" />
                </div>
                <div class="form-group">
                  <label>Nueva contraseña</label>
                  <input v-model="recovery.password" type="password" class="form-control" />
                </div>
                <div class="form-group btn-login-footer">
                  <button type="submit" class="btn btn-default">
                    Guardar nueva contraseña
                  </button>
                </div>
                <div class="form-group btn-login-footer">
                  <button @click.prevent="showForgotPasswordForm = false;showResetPasswordForm = false;" class="btn btn-default">
                    Volver al login
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- Register Tab -->
          <div role="tabpanel" class="tab-pane fade" :id="onMobile ? 'registerMobile' : 'register'">
            <!-- Register Form -->
            <div class="form-container">
              <form @submit.prevent="handleRegister">
                <div class="form-group">
                  <label>Nombre</label>
                  <input v-model="registerForm.name" type="text" class="form-control" autocomplete="off" required />
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input v-model="registerForm.email" type="email" class="form-control" autocomplete="off" required />
                </div>
                <div class="form-group">
                  <label>Telefono *</label>
                  <input v-model="registerForm.phone" type="tel" class="form-control" autocomplete="off" />
                </div>
                <div class="form-group">
                  <label>Contraseña</label>
                  <input v-model="registerForm.password" type="password" class="form-control" autocomplete="off" required />
                </div>
                <div class="form-group">
                  <label>Confirmar contraseña</label>
                  <input v-model="registerForm.password2" type="password" class="form-control" autocomplete="off" required />
                </div>

                <div class="form-group btn-login-footer">
                    <vue-recaptcha
                    v-if="(captchakey !== undefined) &&  (captchakey !== null) && captchakey!='' ? true: false"
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    size="invisible"
                    :loadRecaptchaScript="true"
                    :sitekey="(captchakey !== undefined) && (captchakey !== null)? captchakey : ''"/>

                  <button :disabled="submitting==true" type="submit" class="btn btn-default register-button" >
                    Registrarse
                  </button>
                  <div style="margin-top: 10px">* datos no obligatorios</div>
                </div>
              </form>
            </div>
            <div class="recaptcha-legal-text">
              <small>Este sitio está protegido por reCAPTCHA y se aplican la <a href="https://policies.google.com/privacy">política de privacidad</a> y <a href="https://policies.google.com/terms">términos del servicio</a> de Google.</small>
            </div>
          </div>
        </div>
      </div>
      <div class="login-footer">
        <div class="botones-footer-login">
          <button
              v-on:click="closeLogin"
              class="close-button">
              CERRAR
          </button>
        </div>
        
      </div>
    </div>
    <div class="user-icon" id="user-icon">
      <a v-on:click="openLogin" :class="{ hide: onMobile }">
        <i v-if="user!=null" class="fas fa-user" :title="user.name"></i>
        <i v-else class="fal fa-user"></i>
      </a>
    </div>
  </div>
</template>
<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.js"></script>
<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.min.js"></script>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "MiniLogin",
  components: {
      'vue-recaptcha': VueRecaptcha
    },
  props: ["onMobile", "showLogin"],
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
        remember: false,
      },
      registerForm: {
        name: "",
        email: "",
        phone:"",
        password: "",
        password2:"",
        recaptcha_token:"",
      },
      showError:false,
      errorMessage:'',
      showSuccess:false,
      successMessage:'',
      submitting: false,
      showForgotPasswordForm: false,
      showResetPasswordForm: false,
      recovery: {
        email: null,
        password: null,
        token: null
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      errors: "ecommerce/errors",
      successes: "ecommerce/successes",
      user: "ecommerce/user",
      captchakey: "ecommerce/captchakey",
      passwordRecoveryResponse: "ecommerce/passwordRecoveryResponse"
    }),
  },
  watch: {
    'errors.regError': {
        handler: function (value) {
            if(value){
                this.sendError(this.errors.message);
                this.submitting=false;
            }
        }
    },
    'errors.loginError': {
        handler: function (value) {
            if(value){
                this.sendError(this.errors.message);
            }
        }
    },
    'successes.regSuccess': {
        handler: function (value) {
            if(value){
                this.sendSuccess(this.successes.message);
                this.submitting=false;
            }
        }
    },
    'successes.loginSuccess': {
        handler: function (value) {
            if(value){
                this.sendSuccess(this.successes.message);
            }
        }
    },
    showLogin: {
        handler: function (value) {
            if(value === true){
              this.openLogin()
            } else {
              this.closeLogin()
            }
        }
    },
    passwordRecoveryResponse (value, old){
      if(value !== undefined && value.success !== undefined){
        if(value.success == true){
          if(this.recovery.token !== null){
            this.showResetPasswordForm = false;
            this.loginForm.email = this.recovery.email;
            this.loginForm.password = this.recovery.password;
            this.handleSignIn();
          } else {
            this.showResetPasswordForm = true;
          }

          this.showForgotPasswordForm = false;
          this.sendSuccess(value.message)
        } else {
          this.sendError(value.message)
        }

        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions({
        sendRegister: 'ecommerce/sendRegister',
        sendLogin: 'ecommerce/sendLogin',
        sendLogout: 'ecommerce/sendLogout',
        passwordRecovery: 'ecommerce/passwordRecovery',
        passwordReset: 'ecommerce/passwordReset',
    }),
    ...mapMutations({
      setUser: 'ecommerce/SET_USER',
      clearBasket: 'ecommerce/CLEAR_BASKET',
      clearOrder: 'ecommerce/CLEAR_ORDER',
      cleanErrors: 'ecommerce/CLEAN_ERRORS',
      cleanSuccesses: 'ecommerce/CLEAN_SUCCESSES'
    }),
    handleSignIn() {
        this.sendLogin(this.loginForm);
    },
    handleSignOut(){
        this.setUser({});
        this.clearBasket();
        this.clearOrder();
        this.sendSuccess('Sesión cerrada');
    },
    handleRegister() {
        if(this.registerForm.password===this.registerForm.password2){
            this.$refs.recaptcha.execute();
        }else{
            this.sendError('Las contraseñas no coinciden');
        }
    },
    handlePasswordRecovery() {
      if(this.recovery.email !== undefined && this.recovery.email !== null && this.recovery.email.length > 5 ){
        this.loading = true;
        this.recovery.token = null;
        this.passwordRecovery({email: this.recovery.email});
      }
      else {
        this.sendError('Debe ingresar su email');
      }
    },
    openLogin() {
      document.getElementById("loginSidebar").style.width = "400px"
    },
    closeLogin() {
      if (this.onMobile) {
        this.$emit('close-login')
      } else {
        document.getElementById("loginSidebar").style.width = "0"
      }
      this.showForgotPasswordForm = false
    },
    clickOutsideHandler() {
      if (!this.onMobile) { this.closeLogin() }
    },
    sendError(message){
      this.errorMessage = message
      this.showError = true
      this.cleanErrors()
      let vm = this
      var timer = setTimeout((function() {
          vm.showError = false
          vm.errorMessage = ""
      }),5000)
    },
    sendSuccess(message){
      this.successMessage = message
      this.showSuccess = true
      this.cleanSuccesses()
      let vm = this
      var timer = setTimeout((function() {
          vm.showSuccess = false
          vm.successMessage = ""
      }),5000)
    },
    onCaptchaVerified: function (recaptchaToken) {
        this.registerForm.recaptcha_token = recaptchaToken;
        this.submitting=true;
        this.$refs.recaptcha.reset();
        this.sendRegister(this.registerForm);
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    handleLoginAndCloseModal() {
      if (
        this.recovery.email == null ||
        this.recovery.email == undefined ||
        this.recovery.email == ""
      ) {
        this.sendError("Debes ingresar el email de usuario");
      } else if (
        this.recovery.token === "" ||
        this.recovery.token === null ||
        this.recovery.password === "" ||
        this.recovery.password === null
      ) {
        this.sendError("Debe ingresar el token y la nueva contraseña");
      }else if (
        this.recovery.password === "" ||
        this.recovery.password === null ||
        this.recovery.password.length < 8
      ) {
        this.sendError("La contraseña debe tener al menos 8 caracteres");
      } else {
        this.recovery.password_confirmation = this.recovery.password;
        this.passwordReset(this.recovery);
        this.loading = true;
      }
    },
  },
  directives: {
      losefocus: {
          bind: function (el, binding, vnode) {
              el.clickOutsideEvent = function (event) {
                  if (!(el == event.target || el.contains(event.target))) {
                      vnode.context[binding.expression](event);
                    }
                };
              document.body.addEventListener('click', el.clickOutsideEvent)
            },
          unbind: function (el) {
              document.body.removeEventListener('click', el.clickOutsideEvent)
            },
        }
    }
};
</script>

<style scoped>
.sidebar a:hover {
  color: var(--primary-color);
}
.alert-container {
    position: absolute!important;;
    top: 60px;
    left: 0;
    width: 100%;
}
.login-main {
  height: calc(100% - 80px);
  overflow: auto;
  padding: 1em;
  padding-top: 40px;
}
.login-title {
  height: 50px;
  position: absolute;
  top: 10px;
  left: 33px;
  width: 88%;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 2em;
  font-size: 18px;
  color: #222;
  padding: 8px 4px;
  margin: 0;
}
.nav-tabs > li > a {
  border-bottom: none;
  border: none;
  padding: 10px 0;
  margin-left: 1em;
}

.nav-tabs {
  border-bottom: none;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: none;
  border-bottom-color: transparent;
  border-bottom: 2px solid #ddd;
  border-bottom-color: var(--primary-color);
}
.nav-tabs > li > a {
  border-radius: none;
}
.nav-tabs > li > a:hover {
  border-color: none;
  border-color: transparent;
}
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: transparent !important;
}

.disabled {
  background-color: grey !important;
}

.hide {
  display: none;
}
.sidebar {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: #ffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
  box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.nav {
  position: absolute;
  top: 7px;
  left: 16px;
  width: 90%;
  font-size: 16px;
}

.sidebar .closebtn {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 36px;
  margin-left: 50px;
  color: #888888;
  z-index: 10;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.user-icon {
  transition: margin-left 0.5s;
  padding: 16px 6px;
  cursor: pointer;
  position: relative;
}

.login-main ul {
  position: relative;
}

.mini_login_item {
  list-style: none;
  overflow: hidden;
  padding: 20px 0;
  padding-right: 1em;
  border-bottom: 1px solid #f1f1f1;
}

.user-icon > a {
  font-size: 1.8em;
  padding: 0.25em;
}

/* Forms Styles */
.form-container {
  margin-top: 1em !important;
  max-width: 338px;
  margin: 0 auto;
  padding: 1em;
}

.checkbox {
  margin-top: 2em;
}

.btn-login-footer {
  margin-top: 2em !important;
  width: 100%;
}

.btn-login-footer > .btn-default {
  width: 100%;
  height: 40px;
}

.register-button {
  margin-top: 1.25em;
}

.forgotpassword {
  margin-top: 2em;
}

.forgotpassword > span {
  font-weight: 700;
  font-size: 1.5rem;
}
.abrite {
  width: 100vw !important;
}
.recaptcha-legal-text {
  padding: 0px 10px 30px;
  text-align: center;
  box-sizing: border-box;
}
.login-footer {
  padding: 0 2.25em;
  height: 100px;
}

.login-footer > button {
  margin-top: 1.5em;
  text-align: center;
  width: 100%;
}
.close-button{
  width: 100%;
  margin-bottom: 8px;
}
.close-button:hover {
  background-color: var(--accent-color-dark);
}
</style>
<style>
.grecaptcha-badge { visibility: hidden; }
</style>
