<template>
  <div class="cart-container" v-losefocus="clickOutsideHandler">
    <div id="mySidebar" class="sidebar" :class="{ mobile: onMobile, abrite: showCart }">
      <div class="cart-title">
        <h3>Carrito</h3>
      </div>
      <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav">×</a>
      <transition name="fade">
        <div v-if="showError" class="alert alert-danger alert-container">
            <a class="close" @click.prevent="showError = false">&times;</a>
            <strong>{{errorMessage}}</strong>
        </div>
      </transition>
      <div class="cart-main" :class="{ 'carro-vacio': basket.length === 0 }">
        <transition name="switch">
          <div v-if="basket.length == 0" class="no-products">
            <p class="text">El carrito esta vacio</p>
          </div>
          <div v-else>
            <transition-group
              tag="ul"
              name="list"
              class="minicart-items"
              appear
            >
              <li
                v-for="item in basket"
                :key="item.key"
                class="product-cart mini_cart_item"
              >
                <MiniCartItem :item="item"/>
              </li>
            </transition-group>
          </div>
        </transition>
      </div>

      <transition name="fade">
        <div v-if="showError" class="alert alert-danger alert-container">
            <a class="close" @click.prevent="showError = false">&times;</a>
            <strong>{{errorMessage}}</strong>
        </div>
      </transition>

      <div class="cart-footer">
        <div v-if="basketCount>0" class="cart-subtotal">
          <span>
            Subtotal:
            <span>${{ basketSum }}</span>
          </span>
        </div>
        <button
            v-if="basketCount>0"
            v-on:click="verifyLoggingCondition"
            class="checkout-button">
            INICIAR COMPRA
        </button>
        <div class="botones-footer-cart">
          <button
              v-if="basketCount>0"
              v-on:click="clearBasket"
              class="checkout-button">
              <i class="fa fa-trash-o" aria-hidden="true"></i
        >&nbsp;VACIAR
          </button> 
          <button
              v-on:click="closeNav"
              class="checkout-button">
              CERRAR
          </button>
        </div>
        
      </div>
    </div>

    <div class="basket-icon" id="basket-icon">
      <a v-on:click="openNav" :class="{ hide: onMobile }"
        ><i class="fal fa-shopping-cart" ref="basketIcon"></i
      ></a>
      <span v-if="basketCount > 0" class="count" :class="{ hide: onMobile }"> {{ basketCount }} </span>
    </div>
  </div>
</template>

<script>
import MiniCartItem from "./MiniCartItem.vue";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "MiniCart",
  props: ["onMobile", "showCart"],
  data() {   
    return {
      showError:false,
      errorMessage:''
    };
  },
  components: { MiniCartItem },
  computed: {
    ...mapState('ecommerce', ['basket']),
    ...mapGetters({
      basketCount: "ecommerce/basketCount",
      basketSum: "ecommerce/basketSum",
      configuration: "ecommerce/configuration",
      user: "ecommerce/user",
      errors: "ecommerce/errors"
    })
  },
  watch: {
    /*  'errors.loginError': {
      handler: function (value) {
        if(value){
          this.sendError(this.errors.message);
        }
      }
    }, */
    showCart: {
      handler: function (value) {
        if(value === true){
          this.openNav()
        } else {
          this.closeNav()
        }
      }
    },
    basketSum(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$refs.basketIcon.classList.add("animate-cart");
        setTimeout(() => {
          this.$refs.basketIcon.classList.remove("animate-cart");
        }, 300);
      }
    },
    
  },
  methods: {
    ...mapMutations({
      cleanErrors: "ecommerce/CLEAN_ERRORS",
      setErrorLogin: "ecommerce/SET_ERROR_LOGIN",
      clearBasketMutation: 'ecommerce/CLEAR_BASKET'
    }),
    clearBasket() {
      this.clearBasketMutation()
      this.closeNav()
    },
    openNav() {
      document.getElementById("mySidebar").style.width = "400px";
    },
    closeNav() {
      if (this.onMobile) {
        this.$emit('close-cart')
        // document.getElementsByClassName("mobile")[0].style.width = "0";
      } else {
        document.getElementById("mySidebar").style.width = "0";
      }
    },
    clickOutsideHandler() {
      if (!this.onMobile) { this.closeNav() }
    },
    verifyLoggingCondition(){
        if(this.configuration.allow_sale_unlogged || (!this.configuration.allow_sale_unlogged && this.user!==null)){
            this.closeNav();
            this.$router.push({ name:'CheckoutFlow'}).catch(()=>{});
        }else{
            this.setErrorLogin("Debe loguearse para continuar");
        }
    }/* ,
    sendError(message){
        this.errorMessage=message;
        this.showError = true;
        let vm = this;
        setTimeout((function() {
            vm.errorMessage = '';
            vm.showError = false;
        }),3000);
    } */
  },
  directives: {
    losefocus: {
        bind: function (el, binding, vnode) {
            el.clickOutsideEvent = function (event) {
                if (!(el == event.target || el.contains(event.target))) {
                    vnode.context[binding.expression](event);
                  }
              };
            document.body.addEventListener('click', el.clickOutsideEvent)
          },
        unbind: function (el) {
            document.body.removeEventListener('click', el.clickOutsideEvent)
          },
      }
  }
};
</script>

<style scoped>

.hide {
  display: none;
}
.basket-icon > a {
  font-size: 1.8em;
  padding: 0.25em;
}

.no-products {
  text-align: center;
  padding-top: 50%;
  color: #888;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}

.cart-main {
  height: calc(100% - 190px);
  overflow: auto;
  padding: 1em;
}
.carro-vacio {
  height: calc(100% - 80px)!important;
}
.sidebar {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: #ffff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 70px;
  box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #888888;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: var(--primary-color);
}

.cart-title {
  height: 50px;
  position: absolute;
  top: 10px;
  left: 33px;
  width: 88%;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 2em;
  font-size: 18px;
  color: #222;
  padding: 8px 4px;
  margin: 0;
}

.sidebar .closebtn {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 36px;
  margin-left: 50px;
  color: #888888;
}

.cart-footer {
  padding: 0 2.25em;
  height: 100px;
}

.cart-footer > .cart-subtotal {
  padding-top: 1.75em;
}

.cart-footer > .cart-subtotal > span {
  font-weight: 700;
  font-size: 1.5rem;
  color: #111;
}

.cart-footer > .cart-subtotal > span > span {
  font-size: 2.25rem;
}

.cart-footer > button {
  margin-top: 1.5em;
  text-align: center;
  width: 100%;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.basket-icon {
  transition: margin-left 0.5s;
  padding: 16px 6px;
  cursor: pointer;
}

.router-link-active,
.routerLink{
  margin: 0;
  padding: 8px 0 !important;
}

.checkout-button{
  width: 100%;
  margin-bottom: 8px;
}
.checkout-button+.checkout-button {
  margin-left: 8px;
}


.checkout-button:hover {
  background-color: var(--accent-color-dark);
}

.cart-main ul {
  position: relative;
}

.mini_cart_item {
  list-style: none;
  overflow: hidden;
  padding: 20px 0;
  padding-right: 1em;
  border-bottom: 1px solid #f1f1f1;
}

/* list transitions */
.list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}
.list-enter-to {
  opacity: 1;
  transform: scale(1);
}
.list-enter-active {
  transition: all 0.4s ease;
}

.list-leave-from {
  opacity: 1;
  transform: scale(1);
}

.list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.list-leave-active {
  transition: all 0.4s ease;
  position: absolute;
}

.list-move {
  transition: all 0.3s ease;
}

/* switch transitions */
.switch-enter-from,
.switch-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.switch-enter-to,
.switch-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.switch-enter-active {
  transition: all 0.5s ease;
}

.switch-leave-active {
  transition: all 0.5s ease;
}
.abrite {
  width: 100vw !important;
}
#mySidebar {
  overflow-y: hidden;
}
.animate-cart {
  animation: CartAnimation 0.3s ease-in-out; 
}
@keyframes CartAnimation {
  0% { transform: translateY(0); }
  5% { transform: translateY(-2px); }
  10% { transform: translateY(-4px); }
  15% { transform: translateY(-6px); }
  20% { transform: translateY(-8px); }
  25% { transform: translateY(-10px); }
  30% { transform: translateY(-8px); }
  35% { transform: translateY(-6px); }
  40% { transform: translateY(-4px); }
  45% { transform: translateY(-2px); }
  50% { transform: translateY(-0px); }
  55% { transform: translateY(-1px); }
  60% { transform: translateY(-2px); }
  65% { transform: translateY(-3px); }
  70% { transform: translateY(-4px); }
  75% { transform: translateY(-5px); }
  80% { transform: translateY(-4px); }
  85% { transform: translateY(-3px); }
  90% { transform: translateY(-2px); }
  95% { transform: translateY(-1px); }
  100% { transform: translateY(0px); }
}
.botones-footer-cart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
